<template>
  <!-- 数据统计 - 区域客服业绩统计 -->
  <div class="pageContol listWrap templateList faceStudentstatistics">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">二次结算单查询</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div title="结算单编号" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6rem">结算单编号:</span>
              <el-input
                v-model="retrievalData.billNo"
                clearable
                placeholder="请输入结算单编号"
                size="small"
              ></el-input>
            </div>
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-select
                size="small"
                v-model="retrievalData.compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
                ><el-option
                  v-for="item in CompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <div class="df">
              <el-button
                type="primary"
                size="small"
                class="bgc-bv"
                round
                @click="getData()"
                >查询</el-button
              >
              <el-button
                type="primary"
                size="small"
                class="bgc-bv"
                round
                @click="exportListData"
                >导出</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
              ><el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="100px"
              />
              <el-table-column
                label="结算单编号"
                align="left"
                show-overflow-tooltip
                prop="billNo"
                minWidth="160"
              />
              <el-table-column
                label="机构名称"
                align="left"
                show-overflow-tooltip
                prop="compName"
                minWidth="160"
              />
              <el-table-column
                label="付款金额"
                align="left"
                show-overflow-tooltip
                prop="payerAmount"
                minWidth="100"
              />
              <el-table-column
                label="班级数量"
                align="left"
                show-overflow-tooltip
                prop="projectNum"
                minWidth="100"
              />
              <el-table-column
                label="结算人数"
                align="left"
                show-overflow-tooltip
                prop="settleNum"
                minWidth="100"
              />
              <el-table-column
                label="二次结算班级数量"
                align="left"
                show-overflow-tooltip
                prop="secondProjectNum"
                minWidth="120"
              />
              <el-table-column
                label="二次结算单数量"
                align="left"
                show-overflow-tooltip
                prop="secondBillNum"
                minWidth="120"
              />
              <el-table-column
                label="二次结算人数"
                align="left"
                show-overflow-tooltip
                prop="secondSettleNum"
                minWidth="120"
              />
              <el-table-column
                label="创建日期"
                align="center"
                show-overflow-tooltip
                width="120"
                ><template slot-scope="scope">{{
                  scope.row.createTime | momentDate
                }}</template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="200px"
                fixed="right"
              >
                <div slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="seeSettlementSheet(scope.row)"
                    >查看结算单</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="seeTwoSettlementSheetList(scope.row)"
                    >查看二次结算单</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <!-- 备注弹框 -->
    <el-dialog
      title="二次结算单"
      :visible.sync="dialogVisible_1"
      width="52%"
      top="3%"
      ><div class="ovy-a" style="height: 500px">
        <el-table
          ref="multipleTable"
          :data="twoSettlementSheetListData"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
          ><el-table-column
            label="序号"
            align="center"
            type="index"
            :index="indexMethod"
            width="100px"
          />
          <el-table-column
            label="二次结算单号"
            align="left"
            show-overflow-tooltip
            prop="billNo"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            label="付款金额"
            align="left"
            show-overflow-tooltip
            prop="settlementAmount"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            label="结算人数"
            align="left"
            show-overflow-tooltip
            prop="settleNum"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            label="班级数量"
            align="left"
            show-overflow-tooltip
            prop="projectNum"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="100px"
            fixed="right"
            ><div slot-scope="scope">
              <el-button
                v-if="!scope.row.showInput"
                type="text"
                size="mini"
                style="padding: 0 5px"
                @click="seeTwoSettlementSheet(scope.row, scope.$index, 'edit')"
                >预览</el-button
              >
            </div>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!-- 预览结算 - pdf -->
    <el-dialog
      title="预览"
      :visible.sync="lookfiledialog"
      width="50%"
      top="2%"
      center
      ><div class="ovy-a">
        <div id="pdf-cert1" style="height: 600px"></div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import pdf from "pdfobject";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "operate/faceStudentstatistics",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据集
      retrievalData: {
        // 结算单编号
        billNo: "",
        // 机构名称id
        compId: "",
      },
      // 机构名称 - 下拉数据
      CompanyList: [],
      // 查看二级结算单 - loading
      dialogVisible_1: false,
      // 二级结算单列表数据
      twoSettlementSheetListData: [],
      // 查看附件 - loading;结算单和二次计算单共用一个
      lookfiledialog: false,
    };
  },
  created() {},
  mounted() {},
  methods: {
    // 初始化获取列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      // 培训类型
      if (this.retrievalData.billNo) {
        params.billNo = this.retrievalData.billNo;
      }
      // 机构名称id
      if (this.retrievalData.compId) {
        params.compId = this.retrievalData.compId;
      }
      this.doFetch({
        url: "/biz/new/secondBill/pageList",
        params,
        pageNum,
      });
    },
    // 机构名称
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 导出
    exportListData() {
      const params = {};
      // 培训类型
      if (this.retrievalData.billNo) {
        params.billNo = this.retrievalData.billNo;
      }
      // 机构名称id
      if (this.retrievalData.compId) {
        params.compId = this.retrievalData.compId;
      }
      this.$post("/biz/new/secondBill/pageListExport", params).then((res) => {
        if (res.status == "0") {
          window.open(res.data);
        }
      });
    },
    // 查看结算单
    seeSettlementSheet(row) {
      this.lookfiledialog = true;
      this.$post("/biz/new/bill/review", { billId: row.billId }).then((res) => {
        if (res.status == 0) {
          this.$nextTick(() => {
            pdf.embed(res.message, "#pdf-cert1");
          });
        }
      });
    },
    // 查看二级结算单
    seeTwoSettlementSheetList(row) {
      this.$post("/biz/new/secondBill/secondBillPageList", {
        billId: row.billId,
      }).then((res) => {
        if (res.status == "0") {
          this.twoSettlementSheetListData = res.data;
          this.dialogVisible_1 = true;
        }
      });
    },
    // 预览二级结算单
    seeTwoSettlementSheet(row) {
      this.lookfiledialog = true;
      this.$post("/biz/new/bill/review", { billId: row.billId }).then((res) => {
        if (res.status == 0) {
          this.$nextTick(() => {
            pdf.embed(res.message, "#pdf-cert1");
          });
        }
      });
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less">
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
.faceStudentstatistics {
  .operationControl {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .mabtm {
    padding-bottom: 0.675rem;
    border-bottom: 1px solid #f5f5f6;
  }
  .searchboxItem {
    display: flex;
    flex-direction: row;
    align-items: center;

    .el-cascader {
      height: 32px !important;
    }
  }
}
.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
</style>